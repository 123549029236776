import cn from 'classnames';
import { t } from 'i18n';
import React from 'react';
import styled from 'styled-components';
import ButtonGeneral from '../../ButtonGeneral';
import { Spinner } from '../../Spinner';
import UrlIcon from '../../UrlIcon';
import pauseIcon from '../assets/pause_icon.svg';
import playIcon from '../assets/play_icon.svg';

type tSize = 'sm' | 'md' | 'lg';

export interface IProps {
  isPlaying?: boolean;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  hasError?: boolean;
  isLoading?: boolean;
  className?: string;
  size?: tSize;
}

const PlayButton: React.FC<IProps> = ({
  isPlaying = false,
  onClick,
  disabled,
  isLoading,
  hasError,
  className,
  size = 'lg'
}) => {
  const sizingClassName = `audio-player-size-${size}`;
  const notReadyAriaLabel = isLoading
    ? t('frontend.media_player.audio_player.aria_loading_button')
    : t('frontend.media_player.audio_player.aria_reload_button');
  if (hasError || isLoading) {
    return (
      <SPlayButton
        type="button"
        className={cn(className, sizingClassName)}
        disabled
        aria-label={notReadyAriaLabel}
        title={notReadyAriaLabel}
      >
        <Spinner />
      </SPlayButton>
    );
  }

  const ariaLabel = isPlaying
    ? t('frontend.media_player.audio_player.aria_pause_button')
    : t('frontend.media_player.audio_player.aria_play_button');

  return (
    <SPlayingButton
      type="button"
      onClick={onClick}
      className={cn({ pulsing: isPlaying }, className, sizingClassName)}
      disabled={disabled}
      aria-label={ariaLabel}
      title={ariaLabel}
    >
      <UrlIcon
        width="40px"
        height="40px"
        className="playing-btn-svg"
        url={isPlaying ? pauseIcon : playIcon}
      />
    </SPlayingButton>
  );
};

export default PlayButton;

const SBaseButton = styled(ButtonGeneral)`
  align-self: center;
  background: none;
  border: none;
  position: relative;
  min-width: 80px;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  padding: 0;

  &:focus,
  &:hover {
    outline: none;
    box-shadow: 0 0 3px 3px var(--color-blue-btn-focus-outline-color);
    background: var(--color-blue-btn-focus-bg);
  }

  &.audio-player-size {
    &-md {
      transform: scale(0.8);
    }
    &-sm {
      transform: scale(0.5);
    }
  }
`;

const SPlayingButton = styled(SBaseButton)`
  &:disabled {
    &::before {
      background: #b9b9b9;
    }
    &::after {
      background: radial-gradient(circle, #ffffff 60%, #b9b9b9 65%);
    }

    .playing-btn-svg {
      color: #b9b9b9;
    }
  }

  .playing-btn-svg {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  &.pulsing {
    &::before {
      content: '';
      position: absolute;
      z-index: 0;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 80px;
      height: 80px;
      background: #009ee2;
      border-radius: 50%;
      animation: pulse-border 1500ms ease-out infinite;
    }
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: radial-gradient(circle, #ffffff 60%, #009ee2 65%);
    border-radius: 50%;
    transition: all 200ms;
  }

  .playing-btn:hover:after {
    background-color: darken(#fa183d, 10%);
  }

  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }
`;

const SPlayButton = styled(SBaseButton)`
  background: #009ee2;
`;
