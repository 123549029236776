import React, { useRef, useCallback, useEffect, useState, forwardRef } from 'react';
import { useLessonPause } from 'students/views/shared/hooks';
import { useAudioPlayer } from 'students/views/shared/bundles/audio/hooks';
import PlayProgressButton from './PlayProgressButton';
import { IEventHandlerOptions } from '../../../bundles/audio/models';

interface IProps {
  src: string;
  color?: string;
  progressColor?: string;
  progressInterval?: number;
  className?: string;
  disabled?: boolean;
  autoplay?: boolean;
  autoPlayDelay?: number;
  onEnd?: (options: IEventHandlerOptions) => void;
  onAudioStopped?: () => void;
}

const SvgAudioPlayer = forwardRef<HTMLButtonElement, IProps>(
  (
    {
      src,
      color = '#3a3a8a',
      progressColor = '#ffffff',
      progressInterval = 100,
      autoplay,
      disabled,
      autoPlayDelay,
      className,
      onEnd,
      onAudioStopped
    },
    forwardedRef
  ) => {
    const [progress, setProgress] = useState(autoplay ? 0 : 100);
    const didCancel = useRef(false);

    const handleProgress = useCallback((progress: number) => {
      setProgress(progress);
    }, []);

    const handleStop = useCallback(() => {
      if (!didCancel.current) {
        setProgress(100);
        onAudioStopped && onAudioStopped();
      }
    }, [onAudioStopped]);

    useEffect(() => {
      return () => {
        didCancel.current = true;
      };
    }, []);

    const { stop, togglePlay } = useAudioPlayer({
      src,
      preload: true,
      progressInterval,
      autoplay,
      autoPlayDelay,
      onProgress: handleProgress,
      onStop: handleStop,
      onEnd
    });

    useLessonPause(stop);

    useEffect(() => {
      return () => {
        stop();
      };
    }, [stop]);

    const handleTogglePlay = () => {
      if (disabled) return;
      togglePlay();
    };

    return (
      <PlayProgressButton
        disabled={disabled}
        progress={progress}
        color={color}
        progressColor={progressColor}
        onClick={handleTogglePlay}
        className={className}
        ref={forwardedRef}
      />
    );
  }
);

export default SvgAudioPlayer;
