import { useCallback, useEffect, useRef, useState } from 'react';
import { IAudioPlayer, IAudioPlayerOptions, IEventHandlerOptions } from '../models';
import { HowlAudioPlayer } from '../classes';

interface IReturnedObject {
  play: () => void;
  stop: () => void;
  pause: () => void;
  togglePlay: () => void;
  seek: (seekValue: number) => void;
  togglePlayPause: () => void;
  setVolume: (volume: number) => void;
  getVolume: () => number | undefined;
  setSpeed: (speed: number) => void;
  getSpeed: () => number | undefined;
}

export default function useAudioPlayer({
  src,
  preload,
  progressInterval,
  autoplay,
  autoPlayDelay,
  loop,
  volume,
  onProgress,
  onEnd,
  onError,
  onFade,
  onLoad,
  onMute,
  onPause,
  onPlay,
  onRate,
  onSeek,
  onStop,
  onUnlock,
  onVolume
}: IAudioPlayerOptions): IReturnedObject {
  const [player, setPlayer] = useState<IAudioPlayer | null>(null);
  const autoplayTimeoutRef = useRef<number | null>(null);

  const play = useCallback(() => {
    player?.play();
  }, [player]);

  const stop = useCallback(() => {
    player?.stop();
  }, [player]);

  const togglePlay = useCallback(() => {
    if (player?.playing()) {
      player.stop();
    } else {
      player?.play();
    }
  }, [player]);

  const togglePlayPause = useCallback(() => {
    if (player?.playing()) {
      player.pause();
    } else {
      player?.play();
    }
  }, [player]);

  const seek = useCallback(
    (seekValue: number) => {
      player?.seek(seekValue);
    },
    [player]
  );

  const pause = useCallback(() => {
    player?.pause();
  }, [player]);

  const getVolume = useCallback(() => {
    return player?.volume() as number | undefined;
  }, [player]);

  const setVolume = useCallback(
    (volume: number) => {
      player?.volume(volume);
    },
    [player]
  );

  const getSpeed = useCallback(() => {
    return player?.getSpeed();
  }, [player]);

  const setSpeed = useCallback(
    (speed: number) => {
      player?.setSpeed(speed);
    },
    [player]
  );

  useEffect(() => {
    let player: IAudioPlayer | null = null;
    try {
      const handlePlay = (options: IEventHandlerOptions) => {
        onPlay?.(options);
      };
      player = new HowlAudioPlayer({
        src,
        preload,
        progressInterval,
        autoplay: autoplay && !autoPlayDelay,
        loop,
        volume,
        onProgress,
        onEnd,
        onError,
        onFade,
        onLoad,
        onMute,
        onPause,
        onPlay: handlePlay,
        onRate,
        onSeek,
        onStop,
        onUnlock,
        onVolume
      });

      // Configure delay for autoplaying
      if (autoPlayDelay && autoPlayDelay > 0) {
        autoplayTimeoutRef.current = window.setTimeout(() => {
          player?.play();
        }, autoPlayDelay);
      }
    } catch (e: any) {
      onError && onError(e?.message);
    }

    setPlayer(player);

    return () => {
      player?.unload();
      if (autoplayTimeoutRef.current) window.clearTimeout(autoplayTimeoutRef.current);
    };
  }, [
    src,
    progressInterval,
    autoplay,
    autoPlayDelay,
    preload,
    loop,
    volume,
    onEnd,
    onError,
    onFade,
    onLoad,
    onMute,
    onPause,
    onPlay,
    onProgress,
    onRate,
    onSeek,
    onStop,
    onUnlock,
    onVolume
  ]);

  return {
    play,
    stop,
    togglePlay,
    seek,
    pause,
    togglePlayPause,
    setVolume,
    getVolume,
    getSpeed,
    setSpeed
  };
}
