import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import cn from 'classnames';
import Slider from 'rc-slider';
import { FC, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import volumeIcon from '../../../assets/icons/volume_icon.svg';
import ButtonGeneral from '../../ButtonGeneral';
import UrlIcon from '../../UrlIcon';

interface IVolumeTooltip {
  volume: number | undefined;
  onVolumeChange: (volume: number) => void;
}

const VolumeTooltip: FC<IVolumeTooltip> = ({ volume: initialVolume, onVolumeChange }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [volume, setVolume] = useState<number>(initialVolume ?? 1);

  const changeVolume = useCallback(
    (volume: number) => {
      setVolume(volume);
      onVolumeChange(volume);
    },
    [setVolume, onVolumeChange]
  );

  return (
    <Popover className="ln-relative">
      {({ open }) => (
        <>
          <PopoverButton as="div" className="ln-h-8">
            <SToggleButton
              ref={buttonRef}
              className={cn(
                { active: open },
                'hover:ln-bg-grey-200 active:ln-bg-grey-200 focus:ln-bg-grey-200',
                open && '!ln-bg-grey-200'
              )}
            >
              <UrlIcon url={volumeIcon} height="20px" width="20px" color="#2D2D3A" />
            </SToggleButton>
          </PopoverButton>

          <PopoverPanel
            anchor="left"
            className="ln-z-[100] ln-flex ln-flex-col ln-bg-grey-200 ln-rounded-s-lg ln-py-3 ln-px-3 !ln-min-w-24"
          >
            <SSlider min={0} max={1} step={0.01} value={volume} onChange={changeVolume} />
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default VolumeTooltip;

const SToggleButton = styled(ButtonGeneral)`
  background: transparent;
  border: none;
  height: 32px;
  width: 32px;
  padding: 0;
  margin: 0;
  transition: all 0.3s;
  border-radius: 8px;

  &.active {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const SSlider = styled(Slider)`
  padding: 0 !important;
  height: 8px !important;

  .rc-slider-rail {
    height: 7px;
    background: rgba(45, 45, 58, 0.2);
    border-radius: 10px;
  }

  .rc-slider-track {
    height: 7px;
    background: var(--color-blue);
  }

  .rc-slider-handle {
    background: #ffffff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    border: none;
    height: 16px;
    width: 16px;

    &:focus {
      outline: 3px solid var(--color-blue-btn-focus-outline-color);
      outline-offset: 3px;
    }
  }
`;
