import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { Translate } from 'i18n';
import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import doneIcon from '../../../assets/icons/done_without_bg_icon.svg';
import UrlIcon from '../../UrlIcon';

interface ISpeedDropdown {
  currentSpeed: number | undefined;
  onSpeedChange: (speed: number) => void;
}

const SPEED_CONFIG = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

const SpeedDropdown: FC<ISpeedDropdown> = ({ currentSpeed = 1, onSpeedChange }) => {
  const [speed, setSpeed] = useState(currentSpeed);

  const handleSpeedChange = useCallback(
    (newSpeed: number) => {
      setSpeed(newSpeed);
      onSpeedChange(newSpeed);
    },
    [onSpeedChange, setSpeed]
  );

  return (
    <Popover className="ln-relative ln-rounded-lg ln-p-1 ln-transition-colors hover:ln-bg-grey-bg-2">
      <PopoverButton>{speed}x</PopoverButton>

      <PopoverPanel
        anchor="bottom"
        className="ln-z-[100] ln-flex ln-flex-col ln-bg-white ln-shadow-card ln-rounded-lg ln-py-2 ln-px-3 !ln-min-w-36"
      >
        <p className="ln-text-sm ln-text-black/70 ln-text-start ln-pb-3">
          <Translate str="frontend.media_player.audio_player.playback_speed" />
        </p>

        {SPEED_CONFIG.map((value) => (
          <button
            className="ln-relative ln-text-start ln-cursor-pointer ln-rounded-lg ln-mb-1 ln-px-2 ln-transition-colors hover:ln-bg-grey-bg-2"
            key={value}
            onClick={() => handleSpeedChange(value)}
          >
            {speed === value ? (
              <SUrlIcon
                url={doneIcon}
                height="12px"
                width="16px"
                color="var(--color-blue)"
              />
            ) : null}
            {value}x
          </button>
        ))}
      </PopoverPanel>
    </Popover>
  );
};

export default SpeedDropdown;

const SUrlIcon = styled(UrlIcon)`
  position: absolute;
  inset-inline-end: 6px;
  top: 7px;
`;
